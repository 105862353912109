<template>
  <div id="permit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">权限管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input clearable v-model="queryFrom.permitName" placeholder="权限名" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="queryFrom.keyword" clearable placeholder="权限关键字" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="table_header">
        <el-button type="primary" round size="mini" icon="el-icon-plus" @click="PermitVisible=true">新增</el-button>
      </div>
      <el-table :data="PermitListData" style="width: 100%;fontSize: 14px;" border :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}">
        <el-table-column prop="id" label="权限ID" width="80">
        </el-table-column>
        <!-- <el-table-column prop="parentId" label="父权权限ID" width="100">
        </el-table-column> -->
        <el-table-column prop="permitName" label="权限名称" width="180">
        </el-table-column>
        <el-table-column prop="keyword" label="权限关键字">
        </el-table-column>
        <el-table-column prop="description" label="说明">
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" width="240" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editPermit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="removePermit(scope.row.id)">删除</el-button>

            <!-- <el-button slot="reference" size="mini" type="success" style="#1c84c6;marginLeft:10px" icon="el-icon-finished" @click="setoutPermit(scope.row.id)">分配权限</el-button> -->

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

    </el-card>

    <!-- 新增/编辑权限弹窗 -->
    <el-dialog width="40%" title="权限信息" :visible.sync="PermitVisible" @close='hiddlenPermitDialog'>
      <el-form :model="PermitForm" ref="PermitFormRef" :rules="PermitFormRules">
        <el-form-item label="父权权限ID" label-width="100px">
          <el-input :class="PermitId>0?'updatePermit':''" v-model="PermitForm.parentId" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="权限名" label-width="100px" prop="permitName">
          <el-input :class="PermitId>0?'updatePermit':''" v-model="PermitForm.permitName" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="权限关键字" label-width="100px" prop="keyword">
          <el-input :class="PermitId>0?'updatePermit':''" v-model="PermitForm.keyword" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="权限描述" label-width="100px" prop="description">
          <el-input :class="PermitId>0?'updatePermit':''" type="textarea" v-model="PermitForm.description" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PermitVisible = false">取 消</el-button>
        <el-button type="primary" @click="PermitApi">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  PermitList,
  RoleIdPermitList,
  addPermit,
  updatePermit,
  deletePermit
} from '@/api/permit.js'

export default {
  name: '',
  data () {
    return {
      PermitListData: [], // 角色列表数据
      queryFrom: {
        permitName: '',
        keyword: ''
      },
      pagenum: 1,
      size: 10,
      total: 0,

      PermitId: -1, // 角色id
      PermitVisible: false,
      PermitForm: {
        parentId: '',
        permitName: '',
        keyword: '',
        description: ''
      },
      PermitFormRules: {
        // parentId: [
        //   { required: true, message: "请填写父权权限ID", trigger: "blur" },
        // ],
        permitName: [
          { required: true, message: '请填写权限名', trigger: 'blur' }
        ],
        keyword: [
          { required: true, message: '请填写权限关键字', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请填写权限说明', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // 动态遍历需要传的查询参数
    queryFromData () {
      const date = {}
      for (const key in this.queryFrom) {
        if (this.queryFrom[key] != '') {
          date[key] = this.queryFrom[key]
        }
      }
      return date
    }
  },
  created () {
    this.refreshData()
  },
  methods: {
    getPermitList () {
      PermitList(this.queryFromData, this.pagenum, this.size).then((res) => {
        console.log(res)
        res.data.dataList.map((ele) => {
          ele.createDate = this.$moment(ele.createDate).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        })
        this.PermitListData = res.data.dataList
        this.total = res.data.total
      })
    },
    // 刷新数据
    refreshData () {
      this.size = 10
      this.pagenum = 1
      this.getPermitList()
    },
    // 搜索权限列表
    searchList () {
      this.refreshData()
    },
    // 点击编辑按钮
    editPermit (permit) {
      const { id, parentId, permitName, keyword, description } = permit
      this.PermitForm.parentId = parentId
      this.PermitForm.permitName = permitName
      this.PermitForm.keyword = keyword
      this.PermitForm.description = description
      this.PermitVisible = true
      this.PermitId = id
    },
    // 提交权限更新
    PermitApi () {
      this.$refs.PermitFormRef.validate((valid) => {
        console.log(valid)
        if (valid) {
          if (this.PermitId > 0) {
            updatePermit(this.PermitForm, this.PermitId).then((res) => {
              console.log(res)
              this.PermitVisible = false
              this.$message.success('权限新增成功')
              this.refreshData()
            })
          } else {
            addPermit(this.PermitForm).then((res) => {
              console.log(res)
              this.PermitVisible = false
              this.$message.success('权限更新成功')
              this.refreshData()
            })
          }
        }
      })
    },
    // 点击删除按钮
    removePermit (Permitid) {
      this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deletePermit(Permitid).then((res) => {
            console.log(res)
            this.refreshData()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.size = newSize
      this.getPermitList() // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.pagenum = newPage
      this.getPermitList() // 发起数据请求
    },

    // 隐藏权限信息弹窗
    hiddlenPermitDialog () {
      this.$refs.PermitFormRef.clearValidate() /// 重置新增用户表单
      Object.keys(this.PermitForm).forEach(
        (key) => (this.PermitForm[key] = '')
      )
      this.PermitId = -1
    }
  }
}
</script>

<style lang='less' scoped>
#permit {
  padding: 60px 20px 20px 20px;
}
</style>
